.often label {
  background-color: white;
  margin: 10px;
  padding: 10px;
  width: 22%;
  text-align: center;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.increment .tabc {
  font-weight: bold;
  display: flex;
}
.increment .tabc label {
  background-color: white;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid lightgrey;
}
.increment .tabc span {
  background-color: none;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dappbox input {
  height: 20px;
  width: 20px;
}
.dappbox label {
  padding-left: 10px;
}
.datesi input {
  width: 60%;
  height: 50px;
  border: 1px solid lightgrey;
  padding: 10px;
}
.whit {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.whit h3 {
  color: #304892;
  font-size: 17px;
}
.bknow {
  width: 100%;
  background-color: #df2528;
  border: none;
}
.txpx {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.txpx {
  margin-left: 20px;
}
