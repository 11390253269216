.bsection {
  margin: 10vh;
  background-color: rgb(96, 154, 230);
  height: 15vh;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.bsection p {
  color: white;
  font-size: 30px;
  font-weight: bold;
}
.bsection button {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 80%;
  margin-top: 10%;
  border-radius: 15px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .bsection {
    margin: 10px;
    height: auto;
  }
  .bsection p {
    font-size: 20px;
    padding: 5px;
  }
}
