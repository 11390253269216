.xcard {
    text-align: center;
  }
  
  .xcard form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .xcard label {
    width: 100%;
  }
  
  .xcard input {
    width: calc(100% - 10px);
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 8px;
  }
  
  .xcard button {
    margin-top: 1em;
    padding: 10px 20px;
  }