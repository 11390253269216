@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&display=swap");
body {
  color: #2d438e;
  font-family: "Montserrat", sans-serif;
  background: repeating-linear-gradient(
    90deg,
    #0000 0 calc((100% - 1 * 1px) / 6),
    rgb(211, 211, 227) 0 calc((100% - 1 * 1px) / 6 + 0.8px)
  );
  background-color: #e2e7ee;
  background-image: url("./img/wallpapertd.png");
  height: 100vh;
  font-family: "Quicksand", sans-serif;
  /* background-color: green; */
  /* font-family: 'Maven Pro', sans-serif; */
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.footerx {
  position: fixed;
  top: 80vh;
  bottom: 0;
  width: 5%;
  /* background-color: red; */
  color: white;
  text-align: center;
  height: 10%;
  right: 10vw;
  overflow-y: hidden;
}

.footerdd {
  position: fixed;
  height: 5vh;
  width: 5vw;

  width: 100%;
  z-index: 10000;
  bottom: 10vh;
  right: 5%;
}
.footerdd img {
  height: 100%;
}

@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  opacity: 1;
  animation: flickerAnimation 1.5s infinite;
}
.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* background-color: green; */
  color: white;
  padding: 10px;
  border-radius: 50%;
  z-index: 999;
}
