.bg-footer {
  background-color: #ccd5f4;
  padding: 50px 0 30px;
}
.footer-heading {
  letter-spacing: 2px;
  font-weight: bold;
}
a {
  text-decoration: none;
}
.footer-link a {
  /* color: #acacac; */
  color: #2d438e;

  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
}

.footer-link a:hover {
  color: #1bbc9b;
}

.contact-info {
  /* color: #acacac; */
  color: #2d438e;

  font-size: 14px;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}

.apple {
  background-color: #424041;
  color: #ffffff;
}

.footer-alt {
  /* color: #acacac; */
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: "";
  width: 25px;
  border-bottom: 1px solid #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #1bbc9b;
}
 
