/* BlogPost.css */

.blog-post-full {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post-full h1 {
  font-size: 36px;
  color: #2d438e; /* Dark blue */
  margin-bottom: 20px;
}

.blog-post-full img {
  max-width: 100%;
  height: auto;
  margin: 15px 0;
}

.blog-post-full p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.back-to-blog {
  display: inline-block;
  align-self: center;
  margin-top: 20px;
  padding: 10px 15px;
  color: #fff;
  font-size: 12px;
  background-color: #d32f2f; /* Red */
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-to-blog:hover {
  background-color: #b71c1c; /* Darker red */
}
