.servi {
  padding-top: 10vh;
  padding-bottom: 10vh;
  color: #2d438e;
}
.servi .row .col {
  background-color: white;
  /* padding: 30px; */
  /* border-radius: 15px; */
}
.servi .row .col h3 {
  background-color: white;
  padding: 30px;
  font-weight: bold;
}
.doticon {
  position: absolute;
  background-color: #2d438e;
  padding-top: 10px;
}
.servi .row .col:nth-child(2) {
  border-left: 1px solid lightgrey;
}
.datax {
  height: 30vh;
}
.datax label {
  display: block;
  margin-left: 20px;
}
.datax label::before {
  content:" \00B7";
  padding: 2px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 20px;
  /* background-color: green; */
}

.servi button {
  background-color: brown;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  height: 40px;
}
.heading h1 {
  font-weight: bold;
  color: #2d438e;
}
.heading h1 span {
  font-weight: bold;
  color: red;
}
.srdrop{
  box-shadow: 0px 2px 400px 39px rgba(251, 221, 221, 0.75);
}

@media only screen and (max-width: 800px) {
  .servi button {
    margin-top: 50px;
  }
  .datax {
    margin-bottom: 10vh;
  }
}
