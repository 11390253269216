/* Blog.css */

#blog {
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.heading {
  margin-bottom: 40px;
}

.heading h1 {
  font-size: 36px;
  color: #2d438e; /* Dark blue */
}

.heading h1 span {
  color: #d32f2f; /* Red */
}

.content {
  display: flex;
  justify-content: space-between;
}

.blog-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 3;
}

.blog-post {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px;
  padding: 20px;
  max-width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.blog-post:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-post h2 {
  font-size: 24px;
  color: #2d438e; /* Dark blue */
}

.blog-post img {
  max-width: 100%;
  height: auto;
  margin: 15px 0;
}

.blog-post p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.read-more {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  color: #fff;
  background-color: #d32f2f; /* Red */
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.read-more:hover {
  background-color: #b71c1c; /* Darker red */
}

.sidebar {
  max-width: 250px;
  margin: 0 15px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  flex: 1;
}

.sidebar h2 {
  font-size: 24px;
  color: #2d438e; /* Dark blue */
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  color: #d32f2f; /* Red */
  text-decoration: none;
  transition: color 0.3s ease;
}

.sidebar ul li a:hover {
  color: #b71c1c; /* Darker red */
}

button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d32f2f; /* Red */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #b71c1c; /* Darker red */
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .blog-post {
    max-width: 100%;
  }

  .sidebar {
    max-width: 100%;
  }
}
