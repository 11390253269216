.ucess {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.divx{
    background-color: white;
    padding: 10px;
    height: 50vh;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.divx h3{
   color: #2d438e;
   font-weight: bold;
}
.divx .xx{
    width: 45%;
    margin: 10px;
    background-color: #2d438e;
}
.divx button{
    width: 45%;
    margin: 10px;
    border: none;
    /* background-color: #2d438e; */
}