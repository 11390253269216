.tabssection img {
  /* display: flex; */
  width: 100%;
  height: 40%;
}
.tabssection p {
  color: #2d438e;
  font-weight: bold;
  margin-top: 15px;
  padding: 5px;
  background-color: white;
  width: 80%;
  border-radius: 20px;
}
.tabssection {
  padding: 30px;
  background-color: white;
}
.tabssection .col {
  background-color: rgb(206, 228, 249);
  border: 1px solid white;
  padding: 0;
}
.eee {
  padding: 10px;
  /* margin: 10px; */
}
.csvs h3 {
  text-align: center;
  font-weight: bold;
}
.csvs ul {
  display: flex !important;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}
.csvs ul li {
  width: 21.5%;
  padding: 10px;
  padding: 0;
  margin: 0;
  margin-right: 8px;
  background-color: white;
  border-radius: 10px;
  /* height: 80%; */
}
